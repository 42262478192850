<template>
  <v-card elevation="0">
    <v-card-title>{{ $t("ui_fields") }}</v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(th, i) in FIELDS_DISPLAY" :key="i" class="text-left">
                {{ th }}
              </th>
              <th>{{ $t("ui_actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in fields"
              :key="row.id"
              :class="!row['id'] ? 'c-disabled' : ''"
            >
              <td v-for="(td, i) in FIELDS_DISPLAY" :key="i">
                {{ td === "id" && !row[td] ? "Системное поле" : row[td] }}
              </td>
              <td style="min-width: 120px">
                <v-btn
                  icon
                  color="green"
                  :disabled="!row.id"
                  x-small
                  @click="openEditModal(row)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="pink"
                  :disabled="!row.id"
                  x-small
                  @click="deleteRow(row.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-actions>
      <v-btn color="accent" elevation="1" small @click="openAddModal">{{
        $t("ui_add")
      }}</v-btn>
    </v-card-actions>

    <AdminModalForm
      ref="AdminModalEditForm"
      :fieldsEdit="FIELDS_EDIT"
      @onSave="onEdit"
    />
    <AdminModalForm
      ref="AdminModalCreateForm"
      :fieldsEdit="FIELDS_CREATE"
      @onSave="onSave"
    />
  </v-card>
</template>

<script>
import api from "@/api";
import AdminModalForm from "@/apps/admin/components/AdminModalForm/AdminModalForm.vue";

import { mapState, mapActions } from "vuex";

const FIELDS_DISPLAY = [
  "id",
  "type",
  "title",
  "name",
  "placeholder",
  "options",
  "structure",
];
const FIELDS_EDIT = ["type", "title", "placeholder", "options", "structure"];
const FIELDS_CREATE = [
  "type",
  "name",
  "title",
  "placeholder",
  "options",
  "structure",
];

export default {
  components: { AdminModalForm },
  data() {
    return {
      FIELDS_DISPLAY,
      FIELDS_EDIT,
      FIELDS_CREATE,
    };
  },
  computed: {
    ...mapState("core$common", ["node", "fields"]),
  },
  methods: {
    ...mapActions("core$common", ["getFields"]),
    deleteRow(id) {
      api
        .request({
          name: "deleteField",
          pathParam: { nodeId: this.node.id, fieldId: id },
          showAlert: true,
        })
        .then(() => this.getFields());
    },
    onEdit(data) {
      api
        .request({
          name: "editField",
          pathParam: { nodeId: this.node.id, fieldId: data.id },
          payload: data,
          showAlert: true,
        })
        .then(() => this.getFields());
    },
    onSave(data) {
      api
        .request({
          name: "createField",
          pathParam: this.node.id,
          payload: data,
          showAlert: true,
        })
        .then(() => this.getFields());
    },
    openAddModal() {
      this.$refs.AdminModalCreateForm.open({});
    },
    openEditModal(data) {
      this.$refs.AdminModalEditForm.open(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-disabled {
  opacity: 0.6;
}
</style>
